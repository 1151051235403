<template>
  <div class="mr-4 inline-block">
    <div class="w-60 h-60 bg-pink-salmon-200 rounded-xl shadow-inner mb-5 p-4">
      <div class="relative">
        <img src="../../assets/img/join/comment.svg" class="mx-auto"> 
        <p class="text-pink-salmon text-xl absolute top-2 left-0 right-0 mx-auto">{{slogn}}</p>
      </div>
      <div class="flex items-center w-fu">
        <div class="flex-1">
          <img class="w-full h-auto" :src="careerImage">
        </div>
        <p class="career-text text-4xl mb-0">{{career}}</p>
      </div>
    </div>
    <div class="w-60 h-28 border-4 border-pink-salmon-200 rounded-xl relative">
      <div class="flex items-center absolute -top-5">
        <img src="../../assets/img/join/mmbear.png" class="w-14 h-14 relative">
        <div class="w-32 bg-pink-salmon rounded-br-2xl rounded-tr-md text-xl text-white p-1 relative -left-4 z-0">揪美美</div>
      </div>
      <div class="absolute left-0 right-0 bottom-3 mx-auto">
        <p class="m-0 text-lg font-bold">{{text1}}</p>
        <p class="m-0 text-lg font-bold">{{text2}}</p>
      </div>
      <img src="../../assets/img/join/right.svg" class="w-4 h-4 absolute right-4 bottom-4">
    </div>
  </div>
</template>
<script>
export default {
  props: {
    slogn: {
      type: String,
      default: ''
    },
    careerImage: {
      type: String,
      default: ''
    },
    career: {
      type: String,
      default: ''
    },
    text1: {
      type: String,
      default: ''
    },
    text2: {
      type: String,
      default: ''
    },
  }
}
</script>
<style scoped lang="scss">
.career-text {
  writing-mode: vertical-rl;
}
</style>