<template>
  <p class="text-8xl text-pink-salmon mb-8">
    {{title}}
    <span class="text-5xl content-text">
      {{content}}<span class="highline-text">{{highline}}</span>
    </span>
  </p>
</template>
<script>

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
    highline: {
      type: String,
      default: '',
    }
  }
}
</script>
<style scoped lang="scss">
.content-text {
  position: relative;
  &::before,&::after {
    position: absolute;
    content: '';
    display: inline-block;
    width: 4px;
    height: 40px;
    bottom: 6px;
    transform-origin: center;
    @apply bg-pink-salmon;
  }
  &::before {
    left: -20px;
    transform: rotate(-15deg);
  }
  &::after {
    right: -20px;
    transform: rotate(15deg);
  }
}
.highline-text {
  position: relative;
  &::before {
    z-index: -1;
    position: absolute;
    content: '';
    width: 100%;
    height: 20px;
    bottom: 0px;
    background: #ffdf7c;
  }
}
</style>