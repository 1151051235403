<template>
<div class="carrousel-wrapper slick-box">
  <slick
      ref="slick"
      :options="slickOptions"
    >

    <!-- <img class="cmm-large-slick-slider" src="../../assets/img/join/banner/0.png" >
    <img class="cmm-large-slick-slider" src="../../assets/img/join/banner/1.png" >
    <img class="cmm-large-slick-slider" src="../../assets/img/join/banner/2.png" >
    <img class="cmm-large-slick-slider" src="../../assets/img/join/banner/3.png" >  -->

    <img class="banner-img" v-for="(banner, index) in bannerList" :key="index" :src="banner.image" >
  </slick>
</div>

</template>
<script>
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';

export default {
  props: {
    bannerList: {
      type: Array,
      default: () => ([])
    }
  },
  components: {
    Slick
  },
  data() {
    return {
        slickOptions: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: '60px',
            arrows: false,
            autoplay: true,
            autoplaySpeed: 3000,
            adaptiveHeight: true,
        },
    };
  },
  watch: {
    bannerList() {
      this.$refs.slick.destroy()
      this.$nextTick(() => {
        if(this.bannerList.length > 3) 
        {
          this.slickOptions.slidesToShow = 3
          this.$refs.slick.$el.classList.add('cmm-slick-slider')
        }
        this.$refs.slick.create(this.slickOptions)
        
      })
    }
  }
}
</script>
<style scoped>
.carrousel-wrapper {
  margin-top: 90px;
}
.cmm-normal-slick-slider {
  transform: scale(1);
}
/*.cmm-large-slick-slider {
  transform: scale(1.6);
} */
/* .slick-slider {
  transform: scale(1.6);
} */
.cmm-slick-slider {
  transform: scale(1.5);
}

.slick-slide {
  padding: 0 10px;
  opacity: 0.5;
}

.slick-slide > div {
  background-color: #f00;
  text-align: center;
}

.slick-slide.slick-center {
  opacity: 1;
}

.slick-track {
  /* max-width: 1260px;
  max-height: 630px; */
}

.banner-img {
  /* margin: auto; */
  padding: 0 20px;
  max-width: 932px;
}
</style>