<template>
  <div class="app-wrapper">
    <!--/app-header 網站版頭-->
    <the-header />
    <div class="container-wrapper no-padding md:hidden">
      <div class="padding-header"></div>
      <div class="flex justify-center items-center p-2">
        <swiper :options="swiperOption" ref="mySwiper">
          <!-- 頂部slides -->
          <!-- 
          <swiper-slide
            ><router-link class="w-full" :to="{ name: 'terms' }"><img class="join-slider" src="../assets/img/join/banner/0.png"/></router-link
          ></swiper-slide>
          <swiper-slide
            ><router-link class="w-full" :to="{ name: 'terms' }"><img class="join-slider" src="../assets/img/join/banner/1.png"/></router-link
          ></swiper-slide>
          <swiper-slide
            ><router-link class="w-full" :to="{ name: 'terms' }"><img class="join-slider" src="../assets/img/join/banner/2.png"/></router-link
          ></swiper-slide>
          <swiper-slide
            ><router-link class="w-full" :to="{ name: 'terms' }"><img class="join-slider" src="../assets/img/join/banner/3.png"/></router-link
          ></swiper-slide>
          -->
          <swiper-slide v-for="(banner, index) in homeBannerList" :key="index">
            <router-link class="w-full" :to="{ name: 'terms' }">
              <img class="join-slider" :src="banner.image"/>
            </router-link>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
      <div class="flex justify-center items-center px-2">
        <button class="join-btn w-full mb-2" @click="$router.push('/terms')">
          <img src="../assets/img/join/join_btn.png" alt="" />
        </button>
      </div>
      <!--糾斗-->
      <section>
        <img class="w-full" src="../assets/img/join/join_content_1.png" />
      </section>

      <!--糾是你-->
      <section class="relative join_content_2">
        <img class="w-full" src="../assets/img/join/join_content_2_title.png" />
        <article>
          <div class="flex items-center justify-between">
            <img class="w-1/2" src="../assets/img/join/join_content_2__slider_1.png" />
            <img class="w-1/2" src="../assets/img/join/join_content_2__slider_2.png" />
          </div>
          <div class="flex items-center justify-between">
            <img class="w-1/2" src="../assets/img/join/join_content_2__slider_3.png" />
            <img class="w-1/2" src="../assets/img/join/join_content_2__slider_4.png" />
          </div>
        </article>
      </section>

      <!--糾甘心-->
      <section>
        <img class="w-full" src="../assets/img/join/join_content_3.png" />
      </section>

      <!--糾in-->
      <section>
        <img class="w-full" src="../assets/img/join/join_content_4.png" />
      </section>
      <!--大師開講不糾-->
      <!-- <section>
        <img class="w-full" src="../assets/img/join/linkbanner_lecture.png" />
      </section> -->

      <!--Q&A-->
      <!-- <section>
        <img class="w-full" src="../assets/img/join/linkbanner_Q&A.png" />
        <div class="h-5"></div>
      </section> -->

      <!--現在就加入-->
      <section class="flex justify-center items-center my-10">
        <router-link :to="{ name: 'terms' }" class="px-20 py-4 mx-4 bg-pink-salmon text-center text-white rounded-full shadow-xl w-full"> 現在就加入！ </router-link>
      </section>
    </div>
    <JoinCarrousel class="hidden md:block py-32 overflow-x-hidden" :bannerList="homeBannerList" />
    <div class="hidden md:block JoinCarrousel-btn-wrapper">
      <button class="join-btn w-full mb-2" @click="$router.push('/terms')">
        <img src="../assets/img/join/join_btn.png" alt="" />
      </button>
    </div>

    <div class="hidden md:block max-w-1200 mx-auto text-center">
      <section class="mb-14">
        <JoinTitle title="揪斗！" content="什麼是" highline="揪美美" />
        <div class="flex justify-center">
          <div class="relative -right-12">
            <img src="../assets/img/join/comment1.svg" />
            <p class="absolute top-0 text-xl my-10 ml-8 mr-20 text-left h-14">揪美美是美華泰線上商城旗下的團購選物平台<br />為所有會員找到最划算、最好賣、最好賺的明星爆品</p>
          </div>
          <img src="../assets/img/join/bear.png" class="w-40 relative right-12" />
        </div>
      </section>
      <section class="mb-14">
        <JoinTitle title="揪是你！" content="歡迎加入" highline="揪美美" />
        <div class="flex justify-center">
          <JoinCard v-for="(item, index) in joinCards" :key="index" :slogn="item.slogn" :careerImage="item.careerImage" :career="item.career" :text1="item.text1" :text2="item.text2" />
        </div>
      </section>
      <section class="mb-14">
        <JoinTitle title="揪甘心！" content="團購服務" highline="大升級" />
        <div class="max-w-750 w-full mx-auto ">
          <div class="rounded-t-xl bg-pink-salmon flex justify-center items-center h-12 ">
            <p class="w-28"></p>
            <p class="flex-1 m-0 text-xl text-white">揪美美</p>
            <p class="flex-1 m-0 text-xl text-white">其他團購平台</p>
          </div>
          <div class="rounded-b-xl border-2 border-pink-salmon-200">
            <div class="flex justify-center items-center" v-for="(item, index) in joinTable" :key="index">
              <p class="w-28  m-0 text-base text-pink-salmon py-4">{{ item.title }}</p>
              <p class="flex-1 m-0 text-base text-gray-600 border-b-2 border-dashed border-pink-salmon-200 py-4">{{ item.chill }}</p>
              <p class="flex-1 m-0 text-base text-gray-600 border-b-2 border-dashed border-pink-salmon-200 py-4">{{ item.other }}</p>
            </div>
            <div class="flex justify-center items-center">
              <p class="w-28  m-0 text-base text-pink-salmon py-1">結論</p>
              <div class="flex-1 m-0 text-base py-1">
                <img src="../assets/img/join/win.svg" class="w-12" />
              </div>
              <p class="flex-1 m-0 text-base py-1"></p>
            </div>
          </div>
        </div>
      </section>
      <section class="mb-14">
        <JoinTitle title="揪in！" content="怎麼" highline="加入我們" />
        <div class="flex justify-center mb-12">
          <div class="flex mr-8" v-for="(item, index) in joinUs" :key="index">
            <p class="mb-0 text-6xl text-pink-salmon font-bold mr-4">{{ index + 1 }}</p>
            <div>
              <div class="rounded-full p-4 border-8 border-pink-salmon-200 mb-2">
                <img :src="item.image" class="w-full" />
              </div>
              <p class="mb-0 text-xl">
                {{ item.text }}
              </p>
            </div>
          </div>
        </div>
        <router-link :to="{ name: 'terms' }" class="px-20 py-4  bg-pink-salmon text-white rounded-full shadow-xl">
          現在就加入！
        </router-link>
      </section>
      <!-- <section class="mb-14 flex">
        <div class="flex-1 cursor-pointer">
          <img class="w-full" src="../assets/img/join/lecture.png">
        </div>
        <div class="flex-1 cursor-pointer">
          <img class="w-full" src="../assets/img/join/Q&A.png">
        </div>
      </section> -->
    </div>
    <the-footer />
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import TheHeader from '@/components/TheHeader.vue'
import TheFooter from '@/components/TheFooter.vue'
import JoinTitle from '@/components/join/Title'
import JoinCard from '@/components/join/Card'
import JoinCarrousel from '@/components/join/Carrousel'
import CareerStudent from '../assets/img/join/img_customer_student.svg'
import CareerHomemaker from '../assets/img/join/img_customer_homemaker.svg'
import CareerInitiator from '../assets/img/join/img_customer_initiator.svg'
import CareerOfficeworker from '../assets/img/join/img_customer_officeworker.svg'
import Signup from '../assets/img/join/img_joinstep_signup.svg'
import Audit from '../assets/img/join/img_joinstep_audit.svg'
import Store from '../assets/img/join/img_joinstep_store.svg'
import Money from '../assets/img/join/img_joinstep_money.svg'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    TheHeader,
    TheFooter,
    swiper,
    swiperSlide,
    JoinCarrousel,
    JoinTitle,
    JoinCard
  },
  data() {
    return {
      joinCards: [
        {
          slogn: '想增加零用錢',
          careerImage: CareerStudent,
          career: '大學生',
          text1: '動動手指',
          text2: '就能分享賺錢'
        },
        {
          slogn: '家庭繁忙的',
          careerImage: CareerHomemaker,
          career: '家庭主婦',
          text1: '簡單好用的操作系統',
          text2: '減少選品時間成本'
        },
        {
          slogn: '職業級玩家',
          careerImage: CareerInitiator,
          career: '團主闆娘',
          text1: '品質嚴格把關',
          text2: '上千件商品任你挑'
        },
        {
          slogn: '想財務自由的我',
          careerImage: CareerOfficeworker,
          career: '職場社畜',
          text1: '讓你當自己的老闆',
          text2: '開啟斜槓人生'
        }
      ],
      joinTable: [
        {
          title: '門檻',
          chill: '沒有低消，一入也能買',
          other: '有最低購買數量，賣不出去變庫存'
        },
        {
          title: '制度',
          chill: '無需推廣，無需拉人入會',
          other: '上下級直銷模式'
        },
        {
          title: '商品',
          chill: '全品類來源把關，每月新增商品',
          other: '商品類別侷限'
        },
        {
          title: '獎金',
          chill: '有買有獎金',
          other: '無'
        },
        {
          title: '平台',
          chill: '完善選品系統，訂單管理超輕鬆',
          other: '過往訂單記錄困難'
        }
      ],
      joinUs: [
        {
          image: Signup,
          text: '註冊'
        },
        {
          image: Audit,
          text: '審核'
        },
        {
          image: Store,
          text: '開賣'
        },
        {
          image: Money,
          text: '荷包滿滿'
        }
      ],
      swiperOption: {
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperOption2: {
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  methods: {
    ...mapActions(['getHomeBanner']),
  },
  computed: {
    ...mapGetters(['homeBannerList']),
    swiper() {
      return this.$refs.mySwiper.swiper
    }
  },
  watch: {
    homeBannerList() {
      this.$nextTick(() => {
        this.$refs.mySwiper.swiper.update()
        }
      )
    }
  },
  mounted() {
    this.getHomeBanner()
  },
}
</script>
<style>
.join-slider {
  width: inherit;
}
.join_content_2 {
  background: url(../assets/img/join/join_content_2.png);
}

/* slider circle color */
/* 選中時 */
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #fff;
}
/* 未選中 */
.swiper-pagination-bullet {
  border: 1px solid #fff;
  background-color: transparent;
  opacity: 1;
}

/* 箭頭 < 不能按時 */
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.1;
  background-color: #fff;
  background-image: unset;
  mask: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
}
/* 箭頭 < > 顏色 */
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-color: #fff;
  background-image: unset;
  mask: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
}

/* 箭頭 < 顏色 */
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-color: #fff;
  background-image: unset;
  mask: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
}

/* banner下方 加入按鈕 */
.join-btn {
  background: transparent;
}
.JoinCarrousel-btn-wrapper {
  margin: 0 auto 4rem;
  max-width: 640px;
  min-width: 33%;
}

.slick-slide > div {
  text-align: center;
}
</style>
